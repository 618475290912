<template>

  <HeadCarousel />
  <!--services start -->
    <div class="services_main">
      <div class="container">
        <div class="creative_taital">
          <h1 class="creative_text">ABOUT US</h1>
          <p style="color: #050000; text-align: center;">Hartley Service Centre is a family run business with over 40 years experience providing our customers with dealership quality work at affordable prices. All our fully qualified technicians are trained to manufacturer standard.
                    We aim to provide a high level of customer care and are proud to be a registered member of The Good Garage Scheme</p>
        </div>
            <div class="section_service_2">
              <h1 class="service_text">OUR SERVICES</h1>
            </div>
            <div class="service_main">
              <div class="container">
                <div class="row row-flex">
                  <div class="col-md-4 mb-4">
                    <div class="written_text h-100">
                      <div class="like_icon"><img class="icon" src="@/assets/images/Untitled-2.png"></div>
                      <h1 class="written_h1">MOT TESTING</h1>
                      <p>MOT £40.00 including free retest </p>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div class="written_text h-100">
                      <div class="like_icon"><img class="icon" src="@/assets/images/servicing.png"></div>
                      <h1 class="written_h1">SERVICING</h1>
                      <p>We offer servicing, which complies with industry standards, on all makes and models of vehicles.</p>
                    </div>
                  </div>
                            <div class="col-md-4 mb-4">
                    <div class="written_text h-100">
                      <div class="like_icon"><img class="icon" src="@/assets/images/engine_diagnostics.png"></div>
                      <h1 class="written_h1">ENGINE DIAGNOSTICS</h1>
                      <p>We use the latest dealer level diagnostic equipment including Snap On and Bosch to provide fault diagnostics on all makes and models of vehicles.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="service_main">
              <div class="container">
                <div class="row row-flex">
                  <div class="col-md-4 mb-4">
                    <div class="written_text h-100 ">
                      <div class="like_icon"><img class="icon" src="@/assets/images/body_repairs.png"></div>
                      <h1 class="written_h1">BODY REPAIRS</h1>
                      <p>We use an affiliated Body Shop to carry out crash repairs and paint work.</p>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div class="written_text h-100">
                      <div class="like_icon"><img class="icon" src="@/assets/images/diagnostics.png"></div>
                      <h1 class="written_h1">ELECTRICAL FAULTS</h1>
                      <p>Using the latest dealer level diagnostic equipment we are able to carry out electrical repairs on all makes and models of vehicles</p>
                    </div>
                  </div>
                            <div class="col-md-4 mb-4">
                    <div class="written_text h-100">
                      <div class="like_icon"><img class="icon" src="@/assets/images/tyres1.png"></div>
                      <h1 class="written_h1">TYRES</h1>
                      <p>We are able to offer a large collection of tyres for all makes and models of cars and carry out Balco Laser four wheel alignments</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
  </div>
  <!--services end -->
  <!--touch_section start -->
    <div class="touch_section">
        <div class="container">
            <h1 class="touch_text">Let's Get In Touch!</h1>
        </div>
    </div>

    <div class="lets_touch_main">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="input_main ">
                       <div class="container-fluid">
                          <form action="/contact-form.php" method="POST">
                            <div class="form-group">
                              <input type="text" class="email-bt" placeholder="Name" name="name">
                            </div>
                            <div class="form-group">
                              <input type="text" class="email-bt" placeholder="Email" name="email">
                            </div>
                                <div class="form-group">
                                  <textarea class="message-bt" placeholder="Message" rows="5" id="comment" name="text"></textarea>
                                </div>
                            <input  type="submit" name="submit" value="Send">
                          </form>
                        </div>

                    </div>
                </div>
                <div class="col-sm-12  col-lg-8">
                    <div id="map" ref="map" style="height: 440px;"></div>
                  </div>
            </div>
        </div>
    </div>
    <!--touch_section end -->

</template>

<script>
import { defineComponent } from 'vue'
import HeadCarousel from '@/components/HeadCarousel.vue'

export default defineComponent({
  name: 'Home',
  components: {
    HeadCarousel
  },
  mounted() {
    window.checkAndAttachMapScript(window.initMap);
  }
})
</script>
