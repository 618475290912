<template>
  <!--banner start -->
  <div class="banner-main">
    <div class="container">
      <div id="main_slider" class="carousel slide" data-ride="carousel">
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="gg-image">
              <img class="img-fluid mx-auto d-block" src="../assets/images/good-garage-scheme.jpg">
            </div>
            <div class="btn_main">
              <button type="button" class="btn btn-dark btn-lg"><a href="https://www.goodgaragescheme.com/pages/garage.aspx/6091/Hartley+Service+Centre+Ltd">READ REVIEWS</a></button>
            </div>
          </div>
          <div class="carousel-item">
            <div class="titlepage-h1">
              <div class="hc-image">
                  <img class="img-fluid mx-auto d-block" src="../assets/images/123.png">
              </div>
              <div class="btn_main">
                  <button type="button" class="btn btn-dark btn-lg"><a href="tel:01752 772738">CALL US NOW</a></button>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#main_slider" role="button" data-slide="prev">
          <i class="fa fa-angle-left"></i>
        </a>
        <a class="carousel-control-next" href="#main_slider" role="button" data-slide="next">
          <i class="fa fa-angle-right"></i>
        </a>
      </div>
    </div>
  </div>
  <!--banner end -->
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
  }
})
</script>
