<template>
  <header class="p-0 ">
    <div class="header_main">
        <div class="container">
            <a href="/">
              <img class="logo" src="../assets/images/logo.png">
            </a>
        </div>
    </div>
    <div class="header">
        <div class="container">
            <div class="menu-area">
                <nav class="navbar navbar-expand-lg ">
                    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fa fa-bars"></i>
                    </button>
                    <div class="navbar-collapse collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                    <router-link
                        to="/"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                        >
                        <li
                            :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']"
                        ><a :href="href" @click="navigate" class="nav-link">Home</a></li>
                    </router-link>
                    <router-link
                        to="/about"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                        >
                        <li
                            :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']"
                        ><a :href="href" @click="navigate" class="nav-link">About</a></li>
                    </router-link>
                    <router-link
                        to="/services"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                        >
                        <li
                            :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']"
                        ><a :href="href" @click="navigate" class="nav-link">Services</a></li>
                    </router-link>
                    <router-link
                        to="/contact"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                        >
                        <li
                            :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']"
                        ><a :href="href" @click="navigate" class="nav-link">Contact</a></li>
                    </router-link>
                    
                      <li class="pull-right ml-auto hcontact">
                        <a href="tel:01752 772738">
                          <span class="ficon"><i class="fas fa-phone"></i></span>
                          
                        </a>
                      </li>
                      <li class="pull-right hcontact">
                        <a href="emailto:lisa@hartleyservicecentre.co.uk">
                          <span class="ficon"><i class="fas fa-envelope"></i></span>
                          
                        </a>
                      </li>
                    </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  </header>
</template>
