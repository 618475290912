<template>
    <!--Contact_section start -->
    <div class="contact_main">
        <div class="contact_section_2">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <div class="map_icon">
                            <a href="https://www.google.com/maps/place/Hartley+Service+Centre/@50.3970754,-4.1335345,15z/data=!4m2!3m1!1s0x0:0x307d9f21b8926581?sa=X&ved=2ahUKEwj3pvv0yZrwAhUiShUIHZxjA6MQ_BIwDnoECB8QBQ">
                                <span class="ficon"><i class="fas fa-map-marker-alt"></i></span>
                                
                                <p class="email-text">208 Mannamead Road, Plymouth
                                PL3 5RF<br>Uk</p>
                            </a>
                            
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="map_icon">
                            <a href="tel:01752 772738">
                                <span class="ficon"><i class="fas fa-phone"></i></span>
                                
                                <p class="email-text">01752 772738</p>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="map_icon email-col">
                            <a href="emailto:lisa@hartleyservicecentre.co.uk">
                                <span class="ficon"><i class="fas fa-envelope"></i></span>
                                
                                <p class="email-text">lisa@hartleyservicecentre.co.uk</p>
                            </a>
                            
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="map_icon">
                            <a href="https://www.facebook.com/HartleyServiceStation/" >
                                <span class="ficon"><i class="fab fa-facebook"></i></span>
                                
                                <p class="email-text">Like Us On Facebook</p>
                            </a>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Contact_section end -->

    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                   <p class="copyright_text">© {{year}} All Rights Reserved. </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'

export default {
  setup() {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>
