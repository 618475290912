<template>
  <app-header />
  <router-view/>
  <app-footer />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  components: {
    AppHeader,
    AppFooter
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/bootstrap.native@3.0.15/dist/bootstrap-native-v4.min.js';
    script.onload = function() {
      /* eslint-disable */
      window.theMenu = new BSN.Collapse('.navbar-toggler');
      /* eslint-enable */
    };
    document.body.appendChild(script);
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title + ' | Hartley Service Centre' || 'Hartley Service Centre';
      if (window.theMenu && getComputedStyle(document.querySelector('.navbar-toggler')).display !== 'none') {
        window.theMenu.hide();
      }
    }
  }
}
</script>

<style>
  @import './assets/css/bootstrap.min.css';
  @import './assets/css/style.css';
  @import './assets/css/responsive.css';
</style>

<style lang="scss">
  .header .navbar .navbar-nav {
    .nav-link {
      padding: 10px 30px;

    }
    li:hover, li.active, li.router-link-active {
        background: #11647F;
      }
  }
  .header {
    .hcontact {
      padding: 0 15px;
      .ficon {
        font-size: 25px;
      }
      
      &:hover {
        background: inherit;
        
        .ficon {
          color: #FFF;
        }
      }
    }
  }
  
  .btn_main {
    padding-top: 28px; 
    padding-bottom: 20px; 
  }
  
  .service_main {
    .written_text {
      padding: 25px 25px 0;
      text-align: center;
    }
    .written_h1 {
      margin: 25px 0 20px;
      font-weight: 600;
    }
    p {
      margin: 0 0 20px;
    }
  }
  
  .contact_section_2 {
    a {
      
      color: #222;
      
      p {
        margin-left: 0;
        margin-right: 0;
        margin-top: 5px;
        font-weight: 600;
        font-size: 16px;
      }
    }
    
  }
  
  .ficon {
    color: #11647F;
    font-size: 35px;
  }
  .email-col a {
    word-break: break-word;
  }
  
  @media all and (max-width: 1200px){
    .banner-main .carousel-control-prev, .banner-main .carousel-control-next {
      top: auto;
      bottom: 12px;
    }
    .btn_main {
      
    }
    
    .carousel-inner {
      margin-bottom: 20px;
    }
  }
  
  @media all and (max-width: 998px){
    .btn_main {
      
    }
  }
  
  @media all and (max-width: 768px){
    .navbar-nav {
      padding-top: 15px;
    }
    .banner-main a.carousel-control-prev, .banner-main a.carousel-control-next {
      
    }
    
    .banner-main {
      padding-bottom: 85px;
    }
    
    .btn_main {
      padding-top: 10px; 
      padding-bottom: 10px; 
    }
  
  }

</style>
